<template>
  <div>

    <general-table
      ref="ordersTable"
      :api-url="apiUrl"
      :viw-component="viwComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="true"
      :export-data="true"
      :type="type"
      :columns="columns"
      :selectable="false"
    >

      <template #filter>
        <b-col
          cols="12"
          md="3"
        >
          <b-input-group>
            <b-form-datepicker
              v-model="filter.from"
              placeholder="Start Date"
              reset-button
              close-button
              locale="en-GB"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-input-group></b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-input-group>
            <b-form-datepicker
              v-model="filter.to"
              placeholder="End Date"
              reset-button
              close-button
              locale="en-GB"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-input-group></b-col>

        <b-button
          variant="primary"
          :disabled="Object.values(filter).length < 1"
          @click="$refs.ordersTable.getAllData()"
        >
          Filter
        </b-button>
      </template>

      <template #cell(items_with_price)="data">
        <ul>
          <li
            v-for=" (item,index ) in data.item.items_with_price"
            :key="index"
            class="itemPrice"
          >  <span>product: {{ item.product_name }} ,</span>   <span> Qty: {{ item.quantity }} ,
          </span>
            <span>price: {{ item.price }}
            </span>
          </li>
        </ul>
      </template>
      <template #cell(status)="data">
        <b-badge
          :variant="changeVaraint(data.item.status_id)"

          @click="openStatusModal( data.item.status_id , data.item.id)"
        >        {{ data.item.status }}
        </b-badge>
      </template>

      <b-modal
        ref="modal-status"
        title="Change Order Status"
        @ok.prevent="changeStatus"
      >
        <b-form>
          <b-form-group
            label="status"
            label-for="status"
          >
            <v-select
              v-model="editing_user_id"
              transition=""
              :options="status"
              :filterable="false"
              label="order_status"
              :reduce="status => status.order_status_id"
            />

          </b-form-group>
        </b-form>
        <p class="text-danger">
          {{ errorMsg }}
        </p>
      </b-modal>
    </general-table>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable, vSelect },
  data() {
    return {
      APIURL: 'admin/orders',
      addComponentName: 'false',
      viwComponent: 'show-order',
      deleteContent: false,
      editContent: false,
      exportTable: false,
      loadExport: false,
      loader: false,
      exportedData: [],
      errorMsg: '',
      editing_user_id: '',
      status_id: '',
      orderId: '',
      filter: {},
      status: [],
      type: 'page',
      columns: [
        { key: 'id', label: 'Order Id' },
        { key: 'date', label: 'Date' },
        { key: 'user_name', label: 'User Name' },
        { key: 'user_mobile', label: 'User Phone' },
        { key: 'payment_method', label: 'Payment Method' },
        { key: 'items_with_price', label: 'Products - Quantity' },

        { key: 'total', label: 'Total' },
        { key: 'status', label: 'Order Status' },
        { key: 'actions' },
      ],
    }
  },
  computed: {

    apiUrl() {
      let base = 'admin/orders?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
    apiUrlExport() {
      let base = 'admin/orders?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
  },

  mounted() {
    this.getstatus()
  },
  methods: {
    async getstatus() {
      await axios.get('admin/statuses_list').then(res => {
        if (res.status === 200) {
          this.status = res.data?.data.order
        }
      })
    },

    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type === 1) return 'secondary'
      if (type === 2) return 'info'
      if (type === 3) return 'primary'
      if (type === 4) return 'success'
      if (type === 5) return 'success'

      return 'danger'
    },
    openStatusModal(id, orderId) {
      this.editing_user_id = id
      this.orderId = orderId
      if (id !== 6) {
        this.$refs['modal-status'].show()
      }
    },
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
    async changeStatus() {
      const formData = {
        order_status_id: this.editing_user_id,
      }
      await axios.post(`admin/change_order_status/${this.orderId}`, formData).then(res => {
        if (res.status === 200) {
          this.$refs['modal-status'].hide()
          this.$refs.ordersTable.getAllData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          } setTimeout(() => {
            this.errorMsg = ''
          }, 2500)
        }
      }).finally(() => {
        this.loader = false
      })
    },

  },
}
</script>

<style>
.itemPrice{
  margin-bottom: 5px;
}
</style>
